<template>
    <svg id="dev-kamedin-logo" xmlns="http://www.w3.org/2000/svg"
    width="2.86667in" height="3.13333in"
    viewBox="0 0 860 940">
    <defs>
        <linearGradient id="top-bottom-grad" gradientTransform="rotate(90)">
            <stop offset="-50%" stop-color="#152145"></stop>
            <stop offset="140%" stop-color="#050b1d"></stop>
        </linearGradient>
        <linearGradient id="bottom-top-grad" gradientTransform="rotate(90)">
            <stop offset="0%" stop-color="#050b1d"></stop>
            <stop offset="100%" stop-color="#152145"></stop>
        </linearGradient>
    </defs>
    <path stroke="black" class="logo-paths" filter="url(#logo-shadow)"
    d="M 20 9.33
    C 20 9.33 120 9.33 120 49.33
    C 120 89.33 120 149.33 120 149.33 C 120 149.33 297 140 443.33 206
    C 443.36 206 527 124.33 527 124.33 C 347.33 5.33 20 9.33 20 9.33
    Z
    M 120 169
    C 120 169 160 169 160 209
    C 160 209 160 414 160 414
    C 160 414 76.88 450 61.5 469
    C 61.62 469 154.75 413 258 413
    C 367.25 415.75 435 447.25 435 447 C 535 489 638 489 638 489
    C 638 489 769.75 489 769.75 489
    C 769.75 489 686 481.75 678.5 477.75
    C 690 331.75 660 267 636.25 231
    C 636 230.75 548 309.2 548 309.25 C 574.73 352.36 559.75 450.25 559.75 450.25
    C 559.75 450.25 502.25 435.5 425.5 404
    C 425.5 404 760.25 68.75 760.25 68.75
    C 760.25 68.75 820.75 8.75 840.25 9.25
    C 859.75 9.75 580 9 580 9
    C 580 9 660 9 660 29
    C 660 49 285 384 285 384
    C 285 384 280 384 280 384
    C 280 384 280 189 280 189
    C 280 169 120 169 120 169
    Z"
    />
    <path stroke="black" class="logo-paths" filter="url(#logo-shadow)"
    d="M 40.33,511.00
    C 40.33,511.00 215.00,391.00 420.00,471.00
    C 620.00,551.00 820.00,511.00 820.00,511.00
    C 820.00,511.00 752.50,538.00 670.75,548.25
    C 670.75,548.25 646.00,916.00 20.25,911.00
    C 22.00,912.00 120.00,911.00 120.00,866.00
    C 120.00,821.00 120.00,771.00 120.00,771.00
    C 120.00,771.00 484.00,799.00 547.82,545.36
    C 547.75,545.50 475.09,532.45 418.91,510.82
    C 419.09,510.82 510.18,606.27 510.18,606.27
    C 510.18,606.27 484.73,657.36 433.64,684.45
    C 433.82,684.45 280.00,531.00 280.00,531.00
    C 280.00,531.00 280.00,731.00 280.00,731.00
    C 280.00,731.00 270.00,749.18 120.00,751.00
    C 120.00,751.00 160.00,751.00 160.00,706.00
    C 160.00,661.00 160.00,479.18 160.00,479.18
    C 160.00,479.18 97.82,487.73 40.33,511.00 Z
    M 610.33,717.00
    C 610.33,717.00 577.00,762.00 536.33,786.33
    C 535.00,786.00 660.00,911.00 660.00,911.00
    C 660.00,911.00 660.00,931.00 580.00,931.00
    C 500.00,931.00 840.00,931.00 840.00,931.00
    C 840.00,931.00 805.00,921.00 760.00,871.00
    C 760.00,871.00 610.33,717.00 610.33,717.00 Z"
        />
    <path stroke="black" fill="url(#top-bottom-grad)"
    d="M 20 9.33
    C 20 9.33 120 9.33 120 49.33
    C 120 89.33 120 149.33 120 149.33 C 120 149.33 297 140 443.33 206
    C 443.36 206 527 124.33 527 124.33 C 347.33 5.33 20 9.33 20 9.33
    Z
    M 120 169
    C 120 169 160 169 160 209
    C 160 209 160 414 160 414
    C 160 414 76.88 450 61.5 469
    C 61.62 469 154.75 413 258 413
    C 367.25 415.75 435 447.25 435 447 C 535 489 638 489 638 489
    C 638 489 769.75 489 769.75 489
    C 769.75 489 686 481.75 678.5 477.75
    C 690 331.75 660 267 636.25 231
    C 636 230.75 548 309.2 548 309.25 C 574.73 352.36 559.75 450.25 559.75 450.25
    C 559.75 450.25 502.25 435.5 425.5 404
    C 425.5 404 760.25 68.75 760.25 68.75
    C 760.25 68.75 820.75 8.75 840.25 9.25
    C 859.75 9.75 580 9 580 9
    C 580 9 660 9 660 29
    C 660 49 285 384 285 384
    C 285 384 280 384 280 384
    C 280 384 280 189 280 189
    C 280 169 120 169 120 169
    Z"
    />
    <path stroke="black" fill="url(#bottom-top-grad)"
    d="M 40.33,511.00
    C 40.33,511.00 215.00,391.00 420.00,471.00
    C 620.00,551.00 820.00,511.00 820.00,511.00
    C 820.00,511.00 752.50,538.00 670.75,548.25
    C 670.75,548.25 646.00,916.00 20.25,911.00
    C 22.00,912.00 120.00,911.00 120.00,866.00
    C 120.00,821.00 120.00,771.00 120.00,771.00
    C 120.00,771.00 484.00,799.00 547.82,545.36
    C 547.75,545.50 475.09,532.45 418.91,510.82
    C 419.09,510.82 510.18,606.27 510.18,606.27
    C 510.18,606.27 484.73,657.36 433.64,684.45
    C 433.82,684.45 280.00,531.00 280.00,531.00
    C 280.00,531.00 280.00,731.00 280.00,731.00
    C 280.00,731.00 270.00,749.18 120.00,751.00
    C 120.00,751.00 160.00,751.00 160.00,706.00
    C 160.00,661.00 160.00,479.18 160.00,479.18
    C 160.00,479.18 97.82,487.73 40.33,511.00 Z
    M 610.33,717.00
    C 610.33,717.00 577.00,762.00 536.33,786.33
    C 535.00,786.00 660.00,911.00 660.00,911.00
    C 660.00,911.00 660.00,931.00 580.00,931.00
    C 500.00,931.00 840.00,931.00 840.00,931.00
    C 840.00,931.00 805.00,921.00 760.00,871.00
    C 760.00,871.00 610.33,717.00 610.33,717.00 Z"
        />
    </svg>
</template>



<style scoped>
#dev-kamedin-logo {
    margin: 0 auto;
}
path:not(.logo-paths) {
    filter: drop-shadow(0 0 10px var(--tert));
}
path {
    stroke-width: 10;
    stroke-linejoin: round;
}
.logo-paths {
    fill: none;
}
#dev-kamedin-logo {
    margin-top: 75px;
    transform: scale(.8);
}
@media screen and (min-width: 576px) {
    #dev-kamedin-logo {
        margin-top: 50px;
        transform: scale(1);
    }
}
@media screen and (min-width: 992px) {
    #dev-kamedin-logo {
        margin-top: 0;
        transform: scale(.8);
    }
}
@media screen and (min-width: 1200px) {
    #dev-kamedin-logo {
        transform: scale(1);
    }
}
@media screen and (min-width: 1400px) {
    #dev-kamedin-logo {
        transform: scale(1.4);
    }
}
</style>